import React from 'react';
import { FaMapMarkerAlt, FaEnvelope, FaWhatsapp, FaInstagram, FaFacebook } from 'react-icons/fa';
import './footer.css';

function Footer() {
  return (
    <div className="footerContainer" id='contact'>
      <h2 className="heading">Contact us</h2>
      <h1 className='dot'>......................</h1>
      <p className="subheading">Get in touch with us</p>
      <div className="contentContainer">
        {/* Left side with map and address */}
        <div className="mapAndAddress">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.4424840615!2d80.187933!3d12.9647727!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525de240bace2b%3A0x2df4e9e65a8b46cf!2sAtillas%20Kitchen!5e0!3m2!1sen!2sin!4v1730705255762!5m2!1sen!2sin"
            width="300"
            height="200"
            className="map"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div className="address">
            <h3 className="boldText"><FaMapMarkerAlt />Attila's Kitchen</h3>
            <p>Plot no 2, 265/12, Shop No. 6,</p>
            <p>First Floor, Madras Food Court,</p>
            <p>Medavakkam Main Road,</p>
            <p>Madipakkam, Chennai - 600091</p>
            <p><span className="boldText">Landmark</span><br></br>Near Kovai Pazhamudhir Nilayam</p>
        </div>

        <div className="contactAndSocial">
          <div className="contact">
          <p><FaEnvelope className=""/> <a href="mailto:atillas.madipakkam@gmail.com" className="email"> atillas.madipakkam@gmail.com</a></p>
            <p><FaWhatsapp /> <a href="https://wa.me/919169087087" className="email">+91 91690 87087</a></p>
          </div>
          <div className="socialMedia">
            <h4 className="boldText">Social Media</h4>
            <a href=" https://www.instagram.com/atillaskitchen/profilecard/?igsh=NHlpNTNvYzN2YmNw" target="_blank" rel="noopener noreferrer">
              <p className="email"><FaInstagram /> atillaskitchen</p>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61566775156229&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <p className="email"><FaFacebook /> Atillas Kitchen</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

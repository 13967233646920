import React from 'react';
import './footer.css';

function Footer() {
  return (
    <div className="bottom-f">
      <div className='bottom-footer'>
        <p>© Copyright <span>AJAYKRISHNA FOODS AND RESTAURANTS</span>. All Rights Reserved</p>
        <div className='privacy'>
          <a href="/privacy" target="" rel="">
            <p>Privacy Policy</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React from "react";
import "./partner.css";
import one from '../../../assets/partner-1.jpg';
import two from '../../../assets/partner-2.jpg';
import three from '../../../assets/partner-3.jpg';
import four from '../../../assets/partner-4.jpg';

const Partner = () => {
  return (
    <>
      <section className='partner' id="partner">
        <div className='partner-container'>
          <div className='partner-txt'>
            <h1>Partner With us</h1>
          </div>
          <div className="texted">
            <div className="item-1">
                <img src={one} alt="" className="img-2" />
                <h3>Growth</h3>
                <p>Indo-Chinese cuisine is the most favored and frequently ordered type of food in India, resonating with people of all age groups.</p>
            </div>
            <div className="item-2">
            <img src={two} alt="" className="img-2" />
                <h3>Cuisine Stability</h3>
                <p>The combination of ingredients and pricing potential makes it a more profitable option than traditional Indian ethnic cuisine.</p>
            </div>
            <div className="item-3">
            <img src={three} alt="" className="img-2" />
                <h3>Brand Support</h3>
                <p>At Atilla’s Kitchen, we offer comprehensive brand support through marketing resources, training, and ongoing assistance to ensure our partners’ success.</p>
            </div>
            <div className="item-4">
            <img src={four} alt="" className="img-2" />
                <h3>Franchise Model</h3>
                <p>We provide both FICO and FIFO models tailored to your needs, with investments beginning at ₹30 lakh and a 2-year return on investment (ROI).</p>
            </div>
          </div>
          </div>
      </section>
    </>
  )
}

export default Partner

import React from 'react';
import { useInView } from 'react-intersection-observer';
import './AnimatedSection.css'; // Create a CSS file for styling

const AnimatedSection = ({ children }) => {
  const { ref, inView } = useInView({
    threshold: 0.3, // Trigger when 30% of the section is in view
    triggerOnce: true, // Only trigger once
  });

  return (
    <div ref={ref} className={`animated-section ${inView ? 'visible' : ''}`}>
      {children}
    </div>
  );
};

export default AnimatedSection;

import React from "react"
import home from '../../../assets/home.png';
import "./home.css"

const Home = () => {
  return (
    <>
      <section className='home' id="home">
        <div className='container'>
        <img src={home} alt="" className="home-img" />
        </div>
      </section>
    </>
  )
}

export default Home

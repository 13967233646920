import React from "react";
import "./offering.css";

const Offer = () => {
  return (
    <>
      <section className='offer' id="offering">
        <div className='offer-container'>
          <div className='offer-txt'>
            <h1>Our Offerings</h1>
          </div>
          <div className="para">
            <p><span>Multi-Cuisine Delights:</span> From our signature Chicken and Seafood starters to the exotic Chicken Thokku Biryani, every dish is a flavorful fusion of tradition, inspired by a diverse range of cuisines including Chinese, Korean, Burmese, Thai, South Indian, and Punjabi.</p><br></br>
            <p><span>Catering Services:</span> Hosting a celebration? Let Atilla’s Catering Services make your event unforgettable with our customized, crowd-pleasing menu options.</p><br></br>
            <p><span>Daily Diner Services:</span> Enjoy fresh, home-style meals delivered to your doorstep with our subscription-based delivery services, designed for hassle-free, daily food delivery.</p><br></br>
            <p>At <span>Atilla’s Kitchen</span>, we’re more than just a restaurant. We’re a celebration of food, community, and the joy of sharing meals with those who matter most.</p><br></br>
          </div>
          </div>
      </section>
    </>
  )
}

export default Offer

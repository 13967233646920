import React, { useState } from "react"
import { Link } from "react-router-dom"
import Head from "./Head"
import "./header.css"

const Header = () => {
  const [click, setClick] = useState(false)

  // const scrollToSection = (id) => {
  //   const section = document.getElementById(id);
  //   if (section) {
  //     section.scrollIntoView({ behavior: "instant" });
  //   }
  // };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const isMobile = window.innerWidth <= 768;
      const yOffset = isMobile ? -350 : 0;
      const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <>
      <Head />
      <header>
        <nav className='flexSB'>
          <ul className={click ? "mobile-nav" : "flexSB "} onClick={() => setClick(false)}>
            <li>
              <Link to='' onClick={() => scrollToSection("home")}>Home</Link>
            </li>
            <li>
              <Link to='' onClick={() => scrollToSection("about")}>About Us</Link>
            </li>
            <li>
              <Link to='' onClick={() => scrollToSection("offering")}>Our Offerings</Link>
            </li>
            <li>
              <Link to='' onClick={() => scrollToSection("vision")}>Our vision</Link>
            </li>
            <li>
              <Link to='' onClick={() => scrollToSection("cuisine")}>Our Cuisines</Link>
            </li>
            <li>
              <Link to='' onClick={() => scrollToSection("partner")}>Partner With us</Link>
            </li>
            <li>
              <Link to='' onClick={() => scrollToSection("contact")}>Contact us </Link>
            </li>
          </ul>
          <div className='start'>
            <div className='button'>Click to view our daily food package</div>
          </div>
          <button className='toggle' onClick={() => setClick(!click)}>
            {click ? <i className='fa fa-times'> </i> : <i className='fa fa-bars'></i>}
          </button>
        </nav>
      </header>
    </>
  )
}

export default Header

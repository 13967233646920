import React from "react"
import logo from '../../../assets/logo.png';
import swiggy from '../../../assets/swiggy.png';

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
         <img src={logo} alt="Atilla's Kitchen Logo" className="head-logo" />
          <div className='logo'>
            <h1>Atilla's Kitchen</h1>
            <span>A UNIT OF AJAYKRISHNA FOODS & RESTAURANTS</span>
          </div>

          <div className="App-1">
            <a href="https://www.swiggy.com/direct/brand/566143?source=swiggy-direct&subSource=generic" target="_blank" rel="noopener noreferrer">
              <button className="swiggy-button">
                <img src={swiggy} alt="" className="logo-1" />
              </button>
            </a>
            <a href="https://link.zomato.com/xqzv/rshare?id=88600569855b9835" target="_blank" rel="noopener noreferrer">
                <button className="zomato-button">
                  <p>Zomato</p>
                </button>
            </a>
          </div>
        </div>
        <div className="order">
          <div className="button-1">Order and Get 50% off</div>
        </div>
      </section>
    </>
  )
}

export default Head

import React from "react";
import './vision.css';
import left from '../../../assets/vision-left.jpg';
import right from '../../../assets/vision-right.jpg';
import leaf from '../../../assets/leaf.png';

const Vision = () => {
  return (
    <>
      <section className='vision' id="vision">
        <div className='vision-container'>
          <div className='vision-txt'>
            <img src={leaf} alt="" className="leaf" />
            <h1>Our Visions</h1>
          </div>
        </div>
        <div className="left-img">
            <div className="border">
              <img src={left} alt="" className="img-1" />
            </div>
            <div className="right-side">
              <p>At Atilla’s Kitchen, we aim to be a top destination for food lovers by offering diverse, high-quality dishes that blend tradition with innovation. We are dedicated to creating memorable dining experiences through exceptional taste, warm hospitality, and a commitment to culinary excellence.</p>
              <img src={right} alt="" className="img-2" />
            </div>
        </div>
      </section>
    </>
  )
}

export default Vision

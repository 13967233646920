import React from "react";
import "./App.css"
import Header from "./components/common/header/Header"
import Home from "./components/common/home/Home"
import About from "./components/common/about/About"
import Offering from "./components/common/offering/Offering"
import { BrowserRouter as Router } from "react-router-dom"
import Vision from "./components/common/vision/Vision"
import Cuisine from "./components/common/cuisine/Cuisine"
import Partner from "./components/common/partner/Partner"
import Footer from "./components/common/footer/Footer"
import Bottom from "./components/common/footer/Bottom"
import AnimatedSection from "./components/common/animation/AnimatedSection";

function App() {
  return (
    <>
      <Router>
      <AnimatedSection>
        <Header />
      </AnimatedSection>
      <AnimatedSection>
        <Home />
      </AnimatedSection>
      <AnimatedSection>
        <About />
      </AnimatedSection>
      <AnimatedSection>
        <Offering />
      </AnimatedSection>
      <AnimatedSection>
        <Vision />
      </AnimatedSection>
      <AnimatedSection>
        <Cuisine />
      </AnimatedSection>
      <AnimatedSection>
        <Partner />
      </AnimatedSection>
      <Footer />
      <Bottom />
      </Router>
    </>
  );
}

export default App;
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./cuisine.css";
import left from '../../../assets/cuisine-1.jpg';
import center from '../../../assets/cuisine-2.jpg';
import right from '../../../assets/cuisine-3.jpg';
import lefted from '../../../assets/cuisine-4.jpeg';
import centered from '../../../assets/cuisine-5.jpeg';
import righted from '../../../assets/cuisine-6.jpeg';

const Cuisine = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const slides = [
    {
      image: left,
      title: "Chinese",
      description: "A harmonious balance of bold flavors, featuring stir-fries, savory sauces, and a perfect blend of sweet, sour, and spicy elements.",
    },
    {
      image: center,
      title: "Fusion Biryani",
      description: "A tantalizing blend of Thokku Biryani's tangy masala, Tandoori Biryani's smoky flavors, and Chukka Biryani's spicy, robust dry meat—all combined with aromatic biryani rice for an unforgettable experience.",
    },
    {
      image: right,
      title: "Korean",
      description: "Vibrant and flavorful, known for its spicy kimchi, Chicken dishes, and hearty stews packed with umami-rich ingredients.",
    },
    {
      image: lefted,
      title: "Punjabi",
      description: "Rich, hearty, and full of robust spices, with dishes like buttery curries, tandoori delights, and indulgent breads.",
    },
    {
      image: centered,
      title: "Thai",
      description: "A fragrant fusion of sweet, sour, salty, and spicy flavors, with aromatic herbs and bold curries at the heart of each dish.",
    },
    {
      image: righted,
      title: "Burmese",
      description: "A unique blend of Southeast Asian flavors, combining tangy, spicy, and savory notes with influences from Indian, Chinese, and Thai cuisines, often featuring hearty noodles and rich curries.",
    }
  ];

  return (
    <section className="cuisine" id="cuisine">
      <div className="cuisine-container">
        <div className="cuisine-txt">
          <h1>Atilla's Cuisine</h1>
        </div>
        
        <Slider {...settings} className="slider-container">
          {slides.map((slide, index) => (
            <div key={index} className="slide">
              <div className="img-container">
                <img src={slide.image} alt={slide.title} className="slide-img" />
              </div>
              <div className="text-content">
                <h3>{slide.title}</h3>
                <p>{slide.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="redback"></div>
    </section>
  );
};

export default Cuisine;

import React from "react";
import './about.css';
import left from '../../../assets/about-left.jpg';
import right from '../../../assets/about-right.jpg';
import leaf from '../../../assets/leaft-right.png';
import leafright from '../../../assets/leaf.png';

const Head = () => {
  return (
    <>
      <section className='about' id="about">
        <div className='about-container'>
          <div className='about-txt'>
            <h1>About us</h1>
            <p>WELCOME TO ATTILLA'S KITCHEN – <span>A FLAVORFUL JOURNEY!</span></p>
          </div>
        </div>
        <div className="left-img">
            <img src={left} alt="" className="img-3" />
            <p>At Atilla’s Kitchen, we’re passionate about bringing the finest flavors from across the globe straight to your plate. With our roots in traditional Chinese and South Indian cuisine, and an expanding menu that now includes delectable Pan Asian specialties from Korean, Punjabi, Thai and Burmese cuisines, we craft every dish with love, authenticity, and an eye for perfection.</p>
        </div>
        <img src={leaf} alt="" className="leaf" />
        <div className="right-img">
          <p>Our restaurant is a cozy, family-friendly space, offering a warm and inviting atmosphere for all occasions. Whether you're dining in with loved ones or ordering from the comfort of your home, we promise to deliver a culinary experience that excites your taste buds and leaves you wanting more.</p>
          <img src={right} alt="" className="img-4" />
        </div>
        <img src={leafright} alt="" className="leaf-right" />
      </section>
    </>
  )
}

export default Head
